<template>
  <section class="wrapper" @click.self="cancel()">
    <div class="delete__window-overlay">
      <div class="delete__window">
        <div class="delete__window-title">
          <img src="@/assets/img/alert-modal.svg" alt="" />
          {{ $t('message.confirmation.delete.title') }}
        </div>
        <div class="delete__window-btns">
          <button class="delete__window-btns--cancel delete__window-btns--btn" @click="cancel()">
            {{ $t('message.confirmation.delete.cancel') }}
          </button>
          <button class="delete__window-btns--confirm delete__window-btns--btn" @click="confirm()">
            {{ $t('message.confirmation.delete.confirm') }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {}
  },
  watch: {},
  methods: {
    confirm() {
      this.$emit('confirm')
    },
    cancel() {
      this.$emit('cancel')
    }
  },
  computed: {},
  mounted() {}
}
</script>

<style lang="sass" scoped>
.wrapper
  position: fixed
  top: 0
  left: 0
  height: 100vh
  width: 100vw
  z-index: 4
  background: rgba(0, 0, 0, 0.5)
.delete
  &__window
    background: $white
    box-shadow: 0 rem(4) rem(14) rgba(0, 0, 0, 0.3)
    border-radius: rem(4)
    color: $black
    width: rem(300)
    height: rem(160)
    padding-top: rem(35)
    &-title
      font-style: normal
      font-weight: bold
      font-size: rem(18)
      line-height: rem(22)
      display: flex
      flex-direction: row
      align-items: center
      justify-content: center
      padding-bottom: rem(24)
      img
        padding-right: rem(15)
    &-btns
      &--btn
        font-family: $main-font
        height: rem(30)
        box-shadow: none
        border: none
        background: none
        border-radius: rem(4)
        font-style: normal
        font-weight: 500
        font-size: rem(12)
        line-height: rem(16)
        cursor: pointer
        margin: 0 rem(2)
        outline: none
        transition: .5s
      &--cancel
        background: $ec
        min-width: rem(80)
        &:hover
          background: #cdcdcd
      &--confirm
        background: $red
        min-width: rem(93)
        color: $white
        &:hover
          background: $red-hover
    &-overlay
      display: flex
      flex-direction: row
      justify-content: center
      align-items: center
      position: fixed
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      z-index: 2
      height: rem(180)
      width: rem(320)
      background: $main
      opacity: 0.5
      box-shadow: 0 rem(4) rem(14) rgba(0, 0, 0, 0.16)
      border-radius: rem(8)
</style>
